import { CustomFieldType, CustomLabelField, CustomMultipleChoiceField } from '../services/model/inviteService.model';

export type Event = {
  id: number;
  businessId: number;
  title: string;
  memberCount: number;
  description: string;
  location: string;
  locationUrl?: string;
  startTime: Date;
  endTime?: Date;
  deadline?: Date;
  maximumAttendees?: number;
  picture?: string;
  notificationSettings?: NotificationSettings;
  type: EventType;
  isCancelled: boolean;
  organizer: string;
  role: EventRole;
  participants: EventParticipant[];
  dateOptions: DateOption[];
  isRecurring: boolean;
  frequency: Frequency;
  isDatePicker: boolean;
  recurrenceBatchId: string;
  customFields: EventCustomField[];
};

export enum EventCustomFieldType {
  TEXT = CustomFieldType.TEXT,
  MULTIPLE_CHOICE = CustomFieldType.MULTIPLE_CHOICE,
}

export type EventCustomField = CustomLabelField & {
  responses: EventCustomFieldResponse[];
};

export interface EventCustomFieldResponse {
  userId: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    picture: string;
  };
  response: string;
  customFieldId: number;
}

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface DateOption {
  id: number;
  startTime: Date;
  endTime?: Date;
  answers: DateOptionAnswer[];
}

export interface DateOptionAnswer {
  id: number;
  firstName: string;
  lastName: string;
  picture?: string;
  answer: EventParticipationAnswer;
  description?: string;
}

export enum EventParticipationAnswer {
  YES = 'YES',
  MAYBE = 'MAYBE',
  NO = 'NO',
  PENDING = 'PENDING',
}

export enum RecurrenceEditType {
  SINGLE = 'SINGLE',
  ALL = 'ALL',
  FUTURE = 'FUTURE',
}

export type EventParticipant = {
  id: number;
  firstName: string;
  lastName: string;
  picture: string;
  role?: EventRole;
  description: string;
  answer?: EventParticipationAnswer;
  answers?: DateOptionAnswer[];
};

export enum EventRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum EventType {
  SOCIAL = 'SOCIAL',
  WORK = 'WORK',
  SPORT = 'SPORT',
  OTHER = 'OTHER',
}

export type CreateEventModel = {
  title: string;
  description: string;
  location: string;
  locationUrl?: string; // URL to the location in Google Maps, filled if location is retrieved from the Google Places Api
  startTime: Date;
  endTime?: Date;
  isRecurring?: boolean;
  frequency?: Frequency;
  deadline?: Date;
  recurrenceEndDate?: Date;
  maximumAttendees?: number;
  type: EventType;
  picture?: string;
  isDatePicker: boolean;
  notificationSettings?: NotificationSettings;
  customFields: EventCustomField[];
  dateOptions: Omit<DateOption, 'id' | 'answers'>[];
};

export type NotificationSettings = {
  [userType: string]: {
    [type in EventNotificationType]?: {
      setting: boolean;
    };
  };
};

export enum EventNotificationType {
  EVENT_INVITE = 'EVENT_INVITE',
  EVENT_UPDATE = 'EVENT_UPDATE',
  EVENT_CANCELLED = 'EVENT_CANCELLED',
  EVENT_ANSWER_REMIND = 'EVENT_ANSWER_REMIND',
  EVENT_PARTICIPANT_ANSWERED = 'EVENT_PARTICIPANT_ANSWERED',
  EVENT_PARTICIPANT_DECLINED = 'EVENT_PARTICIPANT_DECLINED',
  EVENT_DATEPICKER_INVITE = 'EVENT_DATEPICKER_INVITE',
  EVENT_DATEPICKER_RESPONDED = 'EVENT_DATEPICKER_RESPONDED',
  EVENT_DATEPICKER_PICKED = 'EVENT_DATEPICKER_PICKED',
}

export type UpdateEventModel = Omit<
  CreateEventModel,
  'isRecurring' | 'frequency' | 'recurrenceEndDate' | 'customFields' | 'isDatePicker'
>;

export enum EventViewType {
  REGULAR = 'REGULAR',
  DATE_PICKER = 'DATE_PICKER',
  PAST = 'PAST',
  ALL_REGULAR = 'ALL_REGULAR',
  ALL = 'ALL',
}

export type AttendantData = {
  id: number;
  name: string;
  yesFraction: number;
  [EventParticipationAnswer.YES]: number;
  [EventParticipationAnswer.NO]: number;
  [EventParticipationAnswer.MAYBE]: number;
  [EventParticipationAnswer.PENDING]: number;
};
