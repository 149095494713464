import { IconType } from 'react-icons';
import { ConnectionType } from '../redux/slices/connectionsSlice';

export interface Auth {
  firstName: string;
  lastName: string;
  email: string;
}

export interface OptionsButtonOption {
  icon?: IconType;
  name: string;
  description?: string;
  linkTo?: string;
  onClick?: () => void;
  className?: string;
}

export type ExcelColumn = { name: string; rows: string[] };

export enum ExcelColumnType {
  FIRST_NAME = 'FIRST_NAME',
  INFIX = 'INFIX',
  LAST_NAME = 'LAST_NAME',
  FULL_NAME = 'FULL_NAME',
  PHONENUMBER = 'PHONENUMBER',
  STREET = 'STREET',
  POSTAL_CODE = 'POSTAL_CODE',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  EMAIL = 'EMAIL',
  BIRTHDATE = 'BIRTHDATE',
  COMMUNICATION_NAME = 'COMMUNICATION_NAME',
  EVENT_PARTICIPATION = 'EVENT_PARTICIPATION',
  COMMENT = 'COMMENT',
  BUSINESSNAME = 'BUSINESSNAME',
}

export type SelectOption = {
  label: string;
  value: string;
} | null;

export enum ApplicationVersion {
  MOMENTS = 'MOMENTS',
  COMMUNITY = 'COMMUNITY',
}

export interface ConnectionIdentifier {
  id: string;
  type: ConnectionType;
  userId?: number; // Hack for profile pictures of personal moment connections
}

export type JwtData = {
  /**
   * The user infromation in the JWT.
   */
  payload: UserJwtToken;
};

/**
 * The user information in the JWT.
 */
type UserJwtToken = {
  /**
   * The user id.
   */
  userId: number;
};

export interface PostcardTemplate {
  /**
   * The ID of the template.
   */
  id: string;

  /**
   * The name of the template.
   */
  name: string;

  /**
   * The format of the template. Can be POSTCARD_A5, POSTCARD_A6, POSTCARD_SQ14 or GREETINGCARD_SQ15
   */
  format: string;

  /**
   * The labels of the template.
   */
  labels: string[];

  /**
   * The merge variables of the template.
   */
  mergeVariables: string[];

  /**
   * The thumbnail of the template in base64.
   */
  thumbnail: string;

  /**
   * The id of the thumbnail. This is use only in case of custom postcard images.
   */
  thumbnailId?: string;
}

export interface PersonalConnection {
  userId: number;
  firstName: string;
  lastName: string;
  picture: string;
}
export interface PicturePathResponse {
  /**
   * The path of the image.
   */
  path: string;
}

export interface Discount {
  code: string;
  amount: number;
}

export interface ConnectionsListConnection {
  id: any;
  alias: string;
  picture?: string;
  disabled?: boolean;
}

export enum OnboardingStep {
  INIT = 0,
  OVERVIEW = 1,
  REQUESTED_DATA = 2,
  COMMUNICATION = 3,
  EVENTS = 4,
  SETTINGS = 5,
  INVITE = 6,
}

export interface AdditionalEventDataMap {
  picture: boolean;
  location: boolean;
  capacity: boolean;
  deadline: boolean;
  notificationSettings: boolean;
}
export enum SubscriptionType {
  FREE = 'FREE',
  BASE = 'BASE',
  PRO = 'PRO',
}
export enum CustomPostcardTemplate {
  ID = 'tmpl_I6wGz4fsoJBSmWMc1olD8',
}

export type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;
