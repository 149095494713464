import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Event } from '../../../types/event';
import { COLOR_PRIMARY_300, COLOR_PRIMARY_900 } from '../../../constants';
import AttendanceTooltip from './components/AttendanceTooltip';
import eventUtils from '../../../utils/eventUtils';
import { decimalToPercentageString } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';

type AttendancePercentageGraphProps = {
  events: Event[];
};

export default function AttendancePerEventGraph({ events }: AttendancePercentageGraphProps): JSX.Element {
  const isMd = useIsScreenSize('md');
  const attendance = eventUtils.transformToAttendanceEntriesPerEvent(events);

  return (
    <ResponsiveContainer width={isMd ? '100%' : '50%'}>
      <BarChart
        data={attendance.map(([, value]) => ({
          ...value,
        }))}
        margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
      >
        <XAxis
          dataKey="name"
          interval={0}
          angle={-45}
          textAnchor="end"
          stroke={COLOR_PRIMARY_900}
        />
        <YAxis domain={[0, 1]} tickFormatter={decimalToPercentageString} stroke={COLOR_PRIMARY_900} />
        <Tooltip cursor={{ fill: '#859BFF2F' }} content={AttendanceTooltip} />
        <Bar
          dataKey="yesFraction"
          fill={COLOR_PRIMARY_300}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
